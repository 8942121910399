<template>
  <div>
    <b-row>
      <b-col md="3"></b-col>
      <b-col md="6">
        <div class="pb-3">{{ $t('label.zones') }}</div>
        <div v-for="(zone) in zones" :key="zone.id" class="position-relative pl-5">
          <app-input
              :label="zone.name"
              :value="zone.bar"
              :placeholder="zone.name"
              required
              @input="onInput($event, zone)"
          />
          <span
              v-if="selectedZones.find(id => id === zone.id)"
              class="delete-btn position-absolute cursor-pointer"
              @click="deleteZone(zone)"
          />
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-input
            :label="$t('label.city_pressure')"
            v-model="form.city"
            :placeholder="$t('label.city_pressure')"
            required
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-input
            :label="$t('label.system_integrity')"
            v-model="form.system_integrity"
            :placeholder="$t('label.system_integrity')"
            required
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="3"></b-col>
      <b-col md="6">
        <app-input
            :label="$t('label.damage_type')"
            v-model="form.damage_type"
            :placeholder="$t('label.damage_type')"
            required
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import _ from 'lodash';
import {mapGetters} from 'vuex';
import AppDatepicker from '@/components/form-groups/AppDatepicker';
import AppInput from '@/components/form-groups/AppInput';
import AppMultiselect from '@/components/form-groups/AppMultiselect';

export default {
  components: {
    AppDatepicker,
    AppInput,
    AppMultiselect
  },
  props: {
    pressure: {
      default: () => {}
    }
  },
  data() {
    return {
      zones: [],
      selectedZones: [],
      form: {
        zones: [],
        city: '',
        system_integrity: '',
        damage_type: '',
      }
    }
  },
  computed: {
    ...mapGetters({
      apiZones: 'fireZonesStore/ZONES'
    })
  },
  watch: {
    pressure(data) {
      if (data) {
        this.$store.dispatch('fireZonesStore/GET_ZONES', {filters: {block: data.block_id}});

        this.form.city = data.city;
        this.form.system_integrity = data.system_integrity;
        this.form.damage_type = data.damage_type;

        if (data.zones.length) {
          data.zones.sort((a,b) => a.id - b.id);
          this.selectedZones = data.zones.map(el => el.id);
          this.zones = data.zones;
        }
      }
    },
    selectedZones: {
      immediate: true,
      handler(data) {
        let zones = [];
        data.forEach(id => {
          const zone = this.zones.find(zone => zone.id === id);
          zones.push(`"${id}":{"bar":"${zone?.bar || zone.name}"}`);
        });
        zones = zones.join(',');
        this.form.zones = '{' + zones + '}';
      },
      deep: true
    },
    apiZones: {
      handler(data) {
        if (data.length) {
          data.forEach(el => {
            if (!this.zones.find(zone => zone.id === el.id)) {
              this.zones.push(el);
            }
          })
        }
      },
      deep: true
    },
    form: {
      handler(data) {
        this.$emit('change', data);
      },
      deep: true
    }
  },
  methods: {
    onInput(e, zone) {
      zone.bar = e;
      this.selectedZones = this.selectedZones.filter(id => id !== zone.id);

      let insertionIndex = this.findInsertionIndex(this.selectedZones, zone.id);
      this.selectedZones.splice(insertionIndex, 0, zone.id);
    },
    deleteZone(zone) {
      zone.bar = '';
      this.selectedZones = this.selectedZones.filter(id => id !== zone.id);
    },
    findInsertionIndex(arr, value) {
      let low = 0;
      let high = arr.length;

      while (low < high) {
        let mid = Math.floor((low + high) / 2);
        if (arr[mid] < value) {
          low = mid + 1;
        } else {
          high = mid;
        }
      }

      return low;
    }
  }
}
</script>

<style lang="scss" scoped>
.delete-btn {
  bottom: 10px;
  right: 15px;

  &:after {
    position: absolute;
    display: inline-block;
    bottom: -6px;
    right: 0;
    font-size: 20px;
    color: #f64e60;
    content: "\00d7";
  }
}
</style>