<template>
  <div>
    <app-card>
      <template #body>
        <fire-water-pressure-form :block-id="blockId" :pressure="pressure" @change="form = $event"/>
      </template>
    </app-card>

    <portal to="subheader-toolbar">
      <b-button class="mr-3" variant="transparent-white" @click="$router.push({name: 'showBlock', params: {id: blockId}, hash: hash})">
        {{ $t('btn.back_to_block') }}
      </b-button>
<!--      <b-button class="mr-3" variant="danger" v-b-modal.delete-block-modal>-->
<!--        {{ $t('btn.delete') }}-->
<!--      </b-button>-->
      <b-button variant="success" @click="updateItem">
        {{ $t('btn.update') }}
      </b-button>
    </portal>

<!--    <app-modal-->
<!--        id="delete-block-modal"-->
<!--        :title="$t('label.confirm_action')"-->
<!--        :ok-text="$t('btn.delete')"-->
<!--        ok-variant="danger"-->
<!--        @ok="deleteItem"-->
<!--    >-->
<!--      <template #app-modal-body>-->
<!--        <span class="font-size-lg">{{ $t('modal.delete_fire_pressure') }}?</span>-->
<!--      </template>-->
<!--    </app-modal>-->
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import AppCard from '@/components/AppCard';
import AppModal from '@/components/modals/AppModal';
import FireWaterPressureForm from './components/FireWaterPressureForm';

export default {
  components: {
    AppCard,
    AppModal,
    FireWaterPressureForm
  },
  data: () => ({
    blockId: null,
    form: {},
    hash: '#fire_water_pressure'
  }),
  beforeMount() {
    this.$store.dispatch('fireWaterPressureStore/GET_PRESSURE', this.$route.params.id);
  },
  computed: {
    ...mapGetters({
      pressure: 'fireWaterPressureStore/PRESSURE'
    })
  },
  watch: {
    pressure(data) {
      this.blockId = data.block_id;
    }
  },
  methods: {
    updateItem() {
      this.$store.dispatch('fireWaterPressureStore/UPDATE', {id: this.pressure.id, data: this.form}).then(() => {
        this.$router.push({name: 'showBlock', params: {id: this.blockId}, hash: this.hash});
      });
    },
    deleteItem() {
      this.$store.dispatch('fireWaterPressureStore/DELETE', this.pressure.id).then(() => {
        this.$router.push({name: 'showBlock', params: {id: this.blockId}, hash: this.hash});
      });
    }
  }
}
</script>